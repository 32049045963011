import { Col, Row, Card } from 'antd';
import React, { useState } from "react";
import { Form } from 'antd';
import { Select } from 'antd';
import DETAIL_MANUAL from './detail-manual';
import BULK_UPLOAD from './detail-bulk';
import BULK_UPLOAD_MANY from './detail-bulk-many';
import DETAIL_TERVERIFIKASI from './detail-verified';

const Option = Select

export const DETAIL_PEMILIH = () => {

  const [selectedTipe, setSelectTipe] = useState('')

  const renderForm = (selectedTipe)=>{
    if(selectedTipe === 'Single Upload NIK'){
      return <DETAIL_MANUAL></DETAIL_MANUAL>
    }else if(selectedTipe === "1 Data Pemilih Terverifikasi DPT"){
      return <DETAIL_TERVERIFIKASI></DETAIL_TERVERIFIKASI>
    }else if(selectedTipe === "Mass Upload Data CSV"){
      return <BULK_UPLOAD_MANY></BULK_UPLOAD_MANY>
    }else{
      return <></>
    }
  }

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Detail Pemilih</h2>
          <p>Update dan tambah data pemilih sesuai dengan dapil yang dituju. <a target='_blank' href="https://docs.google.com/spreadsheets/d/1_13yfd792XswNJ0MMnihBow5puTbtu-StDVLvZT74jU/edit#gid=0">format upload bulk</a> download as CSV</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form.Item
              label="Tipe"
              name="tipe"
            >
              <Select onSelect={(value => {
                setSelectTipe(value)
              })}>
                {/* <Option value={'1 Data Pemilih Terverifikasi DPT'} >1 Data Pemilih Terverifikasi DPT</Option> */}
                <Option value={'Single Upload NIK'} >Upload 1 Data</Option>
                <Option value={'Mass Upload Data CSV'}>Mass Upload Data CSV</Option>
              </Select>
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <Row gutter={6}>
        {renderForm(selectedTipe)}
      </Row>
    </>
  )
}

export default DETAIL_PEMILIH