import { Col, Row, message } from 'antd';
import React, { useState } from "react";
import { Button, Card, Form, Input } from 'antd';
import { Select } from 'antd';
import { useEffect, useCallback } from 'react';
import { addPemilih, updatePemilih, fetchOnePemilih, deletePemilih } from 'redux/features/pemilih';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchAllKabupaten, fetchAllKabupatenQuery,fetchOneKabupaten } from 'redux/features/kabupaten';
import { fetchAllKecamatanQuery,fetchOneKecamatan } from 'redux/features/kecamatan';
import { fetchAllKelurahanQuery,fetchOneKelurahan } from 'redux/features/kelurahan';
import { fetchAllCaleg } from 'redux/features/caleg';

const Option = Select

export const DETAIL_PEMILIH = () => {

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const location = useLocation()
  const history = useHistory()
  const [jabatan, setJabatan] = useState('Belum Ditentukan')
  const [tipe, setTipe] = useState('Belum Terverifikasi')
  const [id_kabupaten, setIdKabupaten] = useState(null)
  const [id_kecamatan, setIdKecamatan] = useState(null)
  const [id_kelurahan, setIdKelurahan] = useState(null)
  const [kabupaten, setKabupaten] = useState(null)
  const [kecamatan, setKecamatan] = useState(null)
  const [kelurahan, setKelurahan] = useState(null)
  const [id_tps, setIdTPS] = useState(null)
  const [id_caleg, setIdCaleg] = useState(null)
  const [daftar_kabupaten, setDaftarKabupaten] = useState([])
  const [daftar_kecamatan, setDaftarKecamatan] = useState([])
  const [daftar_kelurahan, setDaftarKelurahan] = useState([])
  const [daftar_tps, setDaftarTPS] = useState([])
  const [daftar_caleg, setDaftarCaleg] = useState([])
  const [id, setId] = useState('')

  const {
    list,
    filter: { q: searchTerm },
    loading: {
      query: loadingQuery,
      mutation: loadingMutation
    }
  } = useSelector(state => state.kabupaten || [])

  const getData = useCallback(async () => {
    try {
      const response = await dispatch(fetchAllKabupatenQuery(0, 17)).unwrap()
      setDaftarKabupaten(response)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }, [dispatch])

  const getCaleg = useCallback(async () => {
    try {
      const response = await dispatch(fetchAllCaleg()).unwrap()
      setDaftarCaleg(response)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }, [dispatch])

  const getKecamatan = async (value) => {
    try {
      const response = await dispatch(fetchAllKecamatanQuery({
        limit: 100,
        page: 0,
        id_kabupaten: value
      })).unwrap()
      setDaftarKecamatan(response)
      setIdKecamatan(value)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const getKelurahan = async (value) => {
    try {
      const response = await dispatch(fetchAllKelurahanQuery({
        limit: 100,
        page: 0,
        id_kecamatan: value
      })).unwrap()
      setDaftarKelurahan(response)
      setIdKelurahan(value)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const getDataById = async (id) => {
    try {
      await dispatch(fetchAllKabupaten())
      const data = await dispatch(fetchOnePemilih(id)).unwrap()
      form.setFieldsValue({
        ...data[0],
        kabupaten:data[0]?.kabupaten?.name,
        kecamatan:data[0]?.kecamatan?.name,
        kelurahan:data[0]?.kelurahan?.name
      })
      setJabatan(data[0].jabatan)
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const onFinish = async (values) => {
    if (id) {
      updateData({
        ...values,
        id
      })
    } else {
      addData(values)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const addData = async (values) => {
    try {
      await dispatch(addPemilih({
        ...values,
        caleg:id_caleg
      }))
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/pemilih")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const updateData = async (values) => {
    try {
      const response = await dispatch(updatePemilih({
        ...values
      }))
      message.success(response.payload.message)
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/pemilih")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const verifData = async (id) => {
    try {
      const response = await dispatch(updatePemilih({
        id:id,
        isVerified:true
      }))
      message.success(response.payload.message)
      history.push("/app/pemilih")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const tandaiDouble = async (id) => {
    try {
      const response = await dispatch(updatePemilih({
        id:id,
        isDouble:true
      }))
      message.success("Berhasil diverfikasi!")
      history.push("/app/pemilih")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const tandaiDataTidakVerif = async (id) => {
    try {
      const response = await dispatch(updatePemilih({
        id:id,
        isVerified:false
      }))
      message.success(response.payload.message)
      history.push("/app/pemilih")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const deleteData = async () => {
    try {
      await dispatch(deletePemilih(id))
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/pemilih")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  useEffect(() => {
    const itemId = location.state?.id
    getData()
    getCaleg()
    if (itemId) {
      setId(itemId)
      getDataById(itemId)
    }
  }, [])

  return (
    <>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Card>
        <h4>Manual Tanpa Verifikasi</h4>
        <p>Upload data dan perlu diverifikasi secara manual pada saat DPT Online down bisa dicek di <a href='https://cekdptonline.kpu.go.id/' target='_blank'>https://cekdptonline.kpu.go.id</a></p>
          <Form
            name="basic"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >

            <Form.Item
              label="Nama"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Masukan data nama!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="NIK"
              name="nik"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Kabupaten"
              name="kabupaten"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Kecamatan"
              name="kecamatan"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Kelurahan"
              name="kelurahan"
            >
              <Input />
            </Form.Item>

            {/* <Form.Item
              label="Kabupaten"
              name="kabupaten"
            >
              <Select disabled onSelect={(value) => {
                setIdKabupaten(value)
                getKecamatan(value)
              }}>
                {daftar_kabupaten.map(data => {
                  return (
                    <Option value={data.name}>
                      {data.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>

            <Form.Item
                label="Kecamatan"
                name="kecamatan"
              >
                <Select disabled onSelect={(value) => {
                  setIdKecamatan(value)
                  getKelurahan(value)
                }}>
                  {daftar_kecamatan.map(data => {
                    return (
                      <Option value={data?.name}>
                        {data?.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
           <Form.Item
                label="Kelurahan"
                name="kelurahan"
              >
                <Select disabled onSelect={(value) => {
                  setIdKelurahan(value)
                  getKelurahan(value)
                }}>
                  {daftar_kelurahan.map(data => {
                    return (
                      <Option value={data?.name}>
                        {data?.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item> */}

            <Form.Item
              label="TPS"
              name="tps"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Info Koordinator"
              name="info"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Alamat"
              name="alamat"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="No HP"
              name="no_hp"
            >
              <Input />
            </Form.Item>

            {/* <Form.Item
              label="Caleg"
              name="caleg"
            >
              <Select onSelect={(value) => {
                setIdCaleg(value)
              }}>
                {daftar_caleg.map(data => {
                  return (
                    <Option value={data._id}>
                      {data.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item> */}


            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                Submit Data
              </Button>
            </Form.Item>
          </Form>

          <Button onClick={() => { verifData(id) }} type="success" style={{ width: "100%",background:'green',color:"white" }}>
            Tandai Terverifikasi
          </Button>
          <br/>
          <br/>
          <Button onClick={() => { tandaiDataTidakVerif(id) }} type="success" style={{ width: "100%",background:'orange',color:"white" }}>
            Tandai Tidak Terverifikasi
          </Button>
          <br/>
          <br/>
          <Button onClick={() => { tandaiDouble(id) }} type="success" style={{ width: "100%",background:'blue',color:"white" }}>
            Tandai Data Double
          </Button>
          <br/>
          <br/>
          <Button onClick={() => { tandaiDataTidakVerif(id) }} type="success" style={{ width: "100%",background:'orange',color:"white" }}>
            Tandai NIK Tidak Valid
          </Button>
          <br/>
          <br/>

          <Button onClick={() => { deleteData(id) }} type="primary" style={{ width: "100%",background:'red',color:"white" }}>
            Delete Data
          </Button>
        </Card>
      </Col>
    </>
  )
}

export default DETAIL_PEMILIH